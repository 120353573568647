import type { IFormCallMasterModel } from '~/components/M/Forms/MFormCallMaster/MFormCallMaster.model';

export interface IFormOTKModel {
  name: string | number | Date | null;
  email: string | number | Date | null;
  number: string | number | Date | null;
  message: string | number | null;
  rating: number | null;
  captcha?: string | null;
  captcha_key?: string | null;
}

/**
 * @description OTK form model
 */
export class FormOTKModel {
  public name: IFormOTKModel['name'];
  public email: IFormOTKModel['email'];
  public number: IFormOTKModel['number'];
  public message: IFormOTKModel['message'];
  public rating: IFormOTKModel['rating'];
  public captcha: IFormCallMasterModel['captcha'];
  public captcha_key: IFormCallMasterModel['captcha_key'];

  constructor(attrs: Partial<IFormOTKModel> | null = {}, enableCaptcha: boolean = false) {
    attrs = attrs ?? {};

    this.name = attrs.name || null;
    this.email = attrs.email || null;
    this.number = attrs.number || null;
    this.message = attrs.message || null;
    this.rating = attrs.rating || null;

    if (enableCaptcha) {
      this.captcha = attrs.captcha || null;
      this.captcha_key = attrs.captcha_key || null;
    }
  }

  public get captchaEnabled() {
    return this.captcha;
  }

  public set captchaEnabled(captcha: IFormCallMasterModel['captcha']) {
    this.captcha = captcha;
  }

  public reset() {
    this.name = null;
    this.email = null;
    this.number = null;
    this.message = null;
    this.rating = null;
    this.captcha = this.captchaEnabled ? null : undefined;
    this.captcha_key = this.captchaEnabled ? null : undefined;
  }

  public toJSON(): IFormOTKModel {
    return {
      name: this.name,
      email: this.email,
      number: this.number,
      message: this.message,
      rating: this.rating,
      captcha: this.captchaEnabled ? this.captcha : undefined,
      captcha_key: this.captchaEnabled ? this.captcha_key : undefined,
    };
  }
}
