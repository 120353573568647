<template>
  <div class="m-form-otk" :class="formClasses">
    <form class="m-form-otk__form" @submit.prevent="submitHandler" autocomplete="off">
      <div
        v-if="stateModel === EFormStates.SUCCESS"
        class="m-form-otk__form-success m-form__success"
      >
        <div class="m-form__status-title">{{ props.successTitle }}</div>

        <div class="m-form__status-subtitle" v-html="props.successSubtitle"></div>

        <div class="m-form__status-button">
          <Button modifier="tertiary" theme="dark" @click="closeForm('success')">Закрыть</Button>
        </div>
      </div>

      <div
        v-else-if="stateModel === EFormStates.ERROR"
        class="m-form-otk__form-error m-form__error"
      >
        <div class="m-form__status-title">{{ props.errorTitle }}</div>

        <div class="m-form__status-subtitle" v-html="props.errorSubtitle"></div>

        <div class="m-form__status-button">
          <Button modifier="tertiary" theme="dark" @click="closeForm('error')">Вернуться</Button>
        </div>
      </div>

      <div class="m-form-otk__form-default m-form__default">
        <InputText
          v-model="formData.name"
          label="Имя*"
          theme="dark"
          placeholder="Введите имя"
          name="Имя"
          :valid="
            !v$.name.$invalid &&
            !errorsAggregator(v$.name.$errors, formBackendErrors).filter('name').length
          "
          :invalid="!!errorsAggregator(v$.name.$errors, formBackendErrors).filter('name').length"
          :errors="errorsAggregator(v$.name.$errors, formBackendErrors).filter('name')"
          @input="v$.name.$touch()"
          @change="v$.name.$touch()"
        >
          <template #errors="{ errors }">
            <span v-for="(error, i) in errors" :key="`error-${i}`">
              {{ error }}
            </span>
          </template>
        </InputText>

        <InputText
          v-model="formData.email"
          label="Электронная почта*"
          theme="dark"
          type="email"
          placeholder="Введите электронную почту"
          name="Электронная почта"
          :valid="
            !v$.email.$invalid &&
            !errorsAggregator(v$.email.$errors, formBackendErrors).filter('email').length
          "
          :invalid="!!errorsAggregator(v$.email.$errors, formBackendErrors).filter('email').length"
          :errors="errorsAggregator(v$.email.$errors, formBackendErrors).filter('email')"
          @input="v$.email.$touch()"
          @change="v$.email.$touch()"
        >
          <template #errors="{ errors }">
            <span v-for="(error, i) in errors" :key="`error-${i}`">
              {{ error }}
            </span>
          </template>
        </InputText>

        <InputText
          v-model="formData.number"
          label="Номер заявки*"
          theme="dark"
          placeholder="Введите номер заявки"
          name="Номер заявки"
          :valid="
            !v$.number.$invalid &&
            !errorsAggregator(v$.number.$errors, formBackendErrors).filter('number').length
          "
          :invalid="
            !!errorsAggregator(v$.number.$errors, formBackendErrors).filter('number').length
          "
          :errors="errorsAggregator(v$.number.$errors, formBackendErrors).filter('number')"
          @input="v$.number.$touch()"
          @change="v$.number.$touch()"
        >
          <template #errors="{ errors }">
            <span v-for="(error, i) in errors" :key="`error-${i}`">
              {{ error }}
            </span>
          </template>
        </InputText>

        <Textarea
          v-model="formData.message"
          theme="dark"
          label="Отзыв"
          placeholder="Введите текст отзыва"
          :rows="1"
          modifier="multilines"
          v-textarea-auto-height
        >
        </Textarea>

        <div class="m-form-otk__form-rating">
          <div class="m-form-otk__form-labels">Оцените работу мастера</div>

          <Rating v-model="formData.rating" theme="dark" :star-count="5" :rounding-step="1">
            <template #active>
              <i class="icon-main-starfill"></i>
            </template>

            <template #inactive>
              <i class="icon-main-starfill"></i>
            </template>
          </Rating>
        </div>

        <div v-if="getGenericCaptchaState" class="m-form-captcha m-form-captcha--dark">
          <div class="m-form-captcha__header">Введите код с картинки</div>
          <div class="m-form-captcha__box">
            <img
              v-if="captchaImage"
              :src="captchaImage"
              alt="Введите код с картинки"
              title="Введите код с картинки"
              width="120px"
              height="50px"
            />

            <Button
              title="Обновить код капчи"
              aria-label="Обновить код капчи"
              @click="fetchCaptcha"
            >
              <i class="icon-main-reload"></i>
            </Button>
          </div>

          <InputText
            v-model="formData.captcha"
            label="Код*"
            theme="dark"
            placeholder="Введите код"
            name="captcha"
            :invalid="!!errorsAggregator(null, formBackendErrors).filter('captcha').length"
            :errors="errorsAggregator(null, formBackendErrors).filter('captcha')"
          >
            <template #errors="{ errors }">
              <span v-for="(error, i) in errors" :key="`error-${i}`">
                {{ error }}
              </span>
            </template>
          </InputText>
        </div>

        <div class="m-form__form-policy">
          <slot name="policyText">
            Нажимая на кнопку «Отправить», вы соглашаетесь с
            <a href="/docs/privacy_policy.pdf" class="link-dark"
              >политикой обработки персональных данных</a
            >
          </slot>
        </div>

        <Button
          tag="button"
          type="submit"
          css-class="button"
          modifier="primary"
          :loading="stateModel === EFormStates.LOADING || stateModel != EFormStates.DEFAULT"
        >
          <template #loading>
            <Loader></Loader>
          </template>
          <slot name="buttonText">Отправить</slot>
        </Button>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
// vue
import { computed, ref } from 'vue';

// Components
import InputText from '@ice-products-ui/icecorp-vue-library/InputText';
import Textarea from '@ice-products-ui/icecorp-vue-library/Textarea';
import Rating from '@ice-products-ui/icecorp-vue-library/Rating';
import Button from '@ice-products-ui/icecorp-vue-library/Button';
import Loader from '@ice-products-ui/icecorp-vue-library/Loader';

// Directives
import vTextareaAutoHeight from '@ice-products-ui/icecorp-vue-library/TextareaAutoHeight';

// Types
import type { IFormProps, IFormEmits } from '../form.types';
import type { IFormOTKModel } from '~/components/M/Forms/MFormOTK/MFormOTK.model';
import type { IErrorsBackendInterface } from '@ice-products-ui/icecorp-vue-library/ErrorsAggregator';
import type { IGenericData } from '#build/types/ice-core-generic-request';

// Vuelidate
import { useVuelidate } from '@vuelidate/core';
import validations from '../form.validation.messages';

// Models
import { EFormStates, EFormStateMessages } from '~/components/M/Forms/form.types';
import { FormOTKModel } from '~/components/M/Forms/MFormOTK/MFormOTK.model';
import { EFormNames } from '~/composables/molecules/useForms';

// Composables
import useApiBase from '~/composables/api/base/useApiBase';
import useApiCaptcha from '~/composables/api/captcha/useApiCaptcha';

// Utils
import errorsAggregator from '@ice-products-ui/icecorp-vue-library/ErrorsAggregator';
import QueryBuilder from '@ice-products-ui/icecorp-vue-library/QueryBuilder';

const emit = defineEmits<IFormEmits<IFormOTKModel>>();
const props = withDefaults(defineProps<IFormProps>(), {
  loading: false,
  successTitle: EFormStateMessages.SUCCESS_TITLE_OTK,
  successSubtitle: EFormStateMessages.SUCCESS_SUBTITLE_OTK,
  errorTitle: EFormStateMessages.ERROR_TITLE_DEFAULT,
  errorSubtitle: EFormStateMessages.ERROR_SUBTITLE_DEFAULT,
});

const { baseApiUrl } = useApiBase();
const { getGenericCaptchaState, captchaImage, captchaKey, fetchCaptcha } = await useApiCaptcha(
  EFormNames.FORM_OTK,
);

const iceCoreGenericData = useState<IGenericData<Component> | null>('iceCoreGenericData');

const stateModel = ref(EFormStates.DEFAULT);
const formData = ref(new FormOTKModel({}, getGenericCaptchaState.value));
const formBackendErrors = ref<IErrorsBackendInterface | null>(null);

/**
 * @description Form classes
 */
const formClasses = computed(() => {
  return {
    'm-form--default': stateModel.value === EFormStates.DEFAULT,
    'm-form--loading': stateModel.value === EFormStates.LOADING,
    'm-form--success': stateModel.value === EFormStates.SUCCESS,
    'm-form--error': stateModel.value === EFormStates.ERROR,
  };
});

/**
 * @description Close form handler
 * @param status
 */
const closeForm = (status: string) => {
  stateModel.value = EFormStates.DEFAULT;
  emit('update:state', EFormStates.DEFAULT);

  if (status === EFormStates.SUCCESS) {
    formData.value.reset();
    v$.value.$reset();
  }
};

/**
 * @description Validation rules
 * @returns {Object}
 */
const rules = computed(() => {
  return {
    name: { required: validations.required() },
    email: { required: validations.required(), email: validations.email },
    number: {
      required: validations.required(),
      maxLength: validations.maxLength(7),
      integer: validations.integer,
    },
  };
});

const v$ = useVuelidate(rules, formData);

/**
 * @description Form submit handler
 */
const submitHandler = async () => {
  v$.value.$touch();

  if (v$.value.$invalid) return;

  stateModel.value = EFormStates.LOADING;

  if (getGenericCaptchaState.value) {
    formData.value.captcha_key = captchaKey.value;
  }

  const queryStr = new QueryBuilder({
    contexts: iceCoreGenericData.value ? iceCoreGenericData.value.contexts : undefined,
  })
    .clearQuery()
    .buildQueryString();

  await $fetch(`forms/otk?${queryStr}`, {
    baseURL: baseApiUrl,
    method: 'POST',
    body: formData.value,
    onResponseError: (context) => {
      if (context.response.status === 422) {
        formBackendErrors.value = context.response._data;
        stateModel.value = EFormStates.DEFAULT;
        fetchCaptcha();
        return;
      }

      stateModel.value = EFormStates.ERROR;
      emit('update:state', EFormStates.ERROR);
    },
  });

  stateModel.value = EFormStates.SUCCESS;
  emit('update:state', EFormStates.SUCCESS);

  await fetchCaptcha();
};
</script>
